// Custom navbar styling
.navbar-custom {
  background-color: $navbar-custom-background-color;

  .navbar-brand {
    color: $navbar-custom-brand-color;

    @include hover-focus {
      color: $navbar-custom-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      text-transform: uppercase;
      cursor: pointer;

      // Top/bottom padding is 0.5em (8px) by default, not changing this but changing left/right margin
      padding: 0.5em 0.4em;
      margin: 0 0.1em;

      @include media-breakpoint-up(lg) {
        padding: 0.5em 0.75em;
      }

      color: $navbar-custom-color;
      border-radius: $navbar-custom-hover-background-radius;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;

      html:not(.is-touching) &:hover,
      &.focus-visible:focus {
        color: $navbar-custom-hover-color;
        background-color: $navbar-custom-hover-background-color;

        // Needed for focus to remove blue border around item
        outline: none;
      }

      &.disabled {
        color: $navbar-custom-disabled-color;
      }
    }

    // On mobile devices, show a rounded background color when active (clicking)
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active,
    html.is-touching & .nav-link:active {
      color: $navbar-custom-active-color;
      background-color: $navbar-custom-active-background-color;
      border-radius: $navbar-custom-active-background-radius;
    }
  }

  .navbar-toggler {
    color: $navbar-custom-color;
    border-color: $navbar-custom-toggler-border-color;

    border: none !important;

    // Disable outline for not .focus-visible (polyfill for :focus-visible pseudo-state, only set if keyboard focus is given)
    body.js-focus-visible &:focus:not(.focus-visible) {
      outline: none;
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-custom-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-custom-color;
    a {
      color: $navbar-custom-active-color;

      @include hover-focus {
        color: $navbar-custom-active-color;
      }
    }
  }
}

.section {
  // Resolves initial content being cutoff due to sticky navbar
  padding-top: 57px;
  margin-top: -57px;
}
