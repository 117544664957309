$enable-shadows: true;

// Font-related
$font-family-base: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Colors
//
// Used a theme generator specifically made for Bootstrap to get a good starting point for colors
// This is the website: http://colormind.io/bootstrap/
// These are the random colors that were generated (ran it a few times until I liked it):
// ALABASTER - #FBFBFB
// Light shades
// Use this color as the background for your dark-on-light designs, or the text color of an inverted design.
$alabaster: #fbfbfb;

// CASPER - #BCC8D9
// Light accent
// Accent colors can be used to bring attention to design elements by contrasting with the rest of the palette.
$casper: #bcc8d9;

// HAVELOCK BLUE - #4B9BD6
// Main brand color
// This color should be eye-catching but not harsh. It can be liberally applied to your layout as its main identity.
$havelockBlue: #4b9bd6;

// APRICOT - #F08685
// Dark accent
// Another accent color to consider. Not all colors have to be used - sometimes a simple color scheme works best.
$apricot: #f08685;

// FIORD - #454C6A
// Dark shades
$fiord: #454c6a;

// Diane Blue
// Darker color, similar to Fiord to contrast the Havelock Blue in navigation menu
$dianeBlue: #3a6395;

// Dark text color
// Gives good contrast in navbar
$daintree: #00202a;

$bonJour: #f7f5f7;

$slateGray: #637688;

// The following colors below are recommended for the supplementary colors:
$primary: $havelockBlue;
$info: $fiord;
$success: #4ca978;
$warning: #c99940;
$danger: #f44336;
$light: $alabaster;

// Navbar
$navbar-custom-background-color: darken($havelockBlue, 10%);
$navbar-custom-color: $alabaster;
$navbar-custom-hover-color: $daintree;
$navbar-custom-hover-background-color: lighten($havelockBlue, 10%);
$navbar-custom-hover-background-radius: 4px;
$navbar-custom-active-background-color: lighten($havelockBlue, 10%);
$navbar-custom-active-background-radius: 4px;
$navbar-custom-active-color: $daintree;
$navbar-custom-disabled-color: rgba($alabaster, 0.25);
$navbar-custom-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-custom-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$navbar-custom-toggler-border-color: rgba($alabaster, 0.1);
$navbar-custom-brand-color: $alabaster;
$navbar-custom-brand-hover-color: darken($alabaster, 10%);

$navbar-nav-link-padding-x: 0.5rem;
$navbar-brand-font-size: 1.75rem;

// Cards
$card-box-shadow: 0 0.32rem 1.5rem rgba(#000000, 0.2);
$card-border-radius: 0.25rem;
$card-spacer-y: 0.25rem;
$card-spacer-x: 1rem;

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  6: (
    $spacer * 0.75
  ),
  "2-5": (
    $spacer * 0.75
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  )
);
// Breakpoint is between xs (0px) and sm (576px), typically we would rename xs to xxs and then make this xs.
// But that would break the reactstrap library I think, so I'm naming these smx (not extra small, but one size above xs and below sm)
// Note: This seems to do something weird to the Prettier SASS processor, I think because smx is not in quotes? It's valid though?
$grid-breakpoints: (
  xs: 0,
  smx: 350px,
  smxx: 450px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  smx: 540px,
  smxx: 540px,
  md: 720px,
  lg: 1280px,
  xl: 1520px
);
