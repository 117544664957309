#experience {
  // Variables for timeline
  $job-meta-max-width: 275px;
  $timeline-line-padding-left: 30px;
  $timeline-line-padding-right: 30px;
  $timeline-line-thickness: 3px;
  $timeline-line-color: $primary;
  $timeline-line-border: $timeline-line-thickness solid $timeline-line-color;
  $timeline-item-margin-bottom: 45px;
  $timeline-circle-outer-radius: 11px;
  $timeline-circle-inner-radius: 5px;
  $timeline-place-font-size: 1.5rem;
  $timeline-location-font-size: 0.85rem;
  $timeline-title-font-size: 1.25rem;

  .timeline {
    border-left: $timeline-line-border;

    // No margin/padding on left because job title & date are stacked vertically
    // Leave padding on left for the timeline line though
    margin-left: 0;
    padding: 0 0 0 $timeline-line-padding-left;

    // Allow padding for the job title & date to be placed on left
    @include media-breakpoint-up(lg) {
      padding: 0 $timeline-line-padding-left;
      margin-left: $job-meta-max-width + $timeline-line-padding-left +
        $timeline-line-padding-right;
    }

    .item {
      position: relative;
      margin-bottom: $timeline-item-margin-bottom;

      // Dont add a margin to the last element
      &:last-child {
        margin-bottom: 0;
      }

      // Positions outer bubble
      &:before {
        content: "";
        position: absolute;
        left: -$timeline-line-padding-left - $timeline-circle-outer-radius -
          ($timeline-line-thickness / 2);
        top: 0;

        // Width & height is the diameter (2 * radius), make radius 50% for circle
        width: $timeline-circle-outer-radius * 2;
        height: $timeline-circle-outer-radius * 2;
        border-radius: 50%;

        background: $white;
        border: $timeline-line-border;
      }

      &:after {
        content: "";
        position: absolute;
        background: $timeline-line-color;

        // Position the inner circle perfectly inside the outer circle
        left: -$timeline-line-padding-left - $timeline-circle-inner-radius -
          ($timeline-line-thickness / 2);
        top: $timeline-circle-outer-radius - $timeline-circle-inner-radius;

        // Width & height is the diameter (2 * radius), make radius 50% for circle
        // Set Z-index to ensure inner circle is above outer circle
        width: $timeline-circle-inner-radius * 2;
        height: $timeline-circle-inner-radius * 2;
        border-radius: 50%;
        z-index: 10;
      }
    }

    .place {
      margin-top: 0;
      margin-bottom: 5px;

      font-size: $timeline-place-font-size;
      font-weight: 300;
      color: $daintree;

      // Increase margin on large screens because this is where the job title & date are on the left
      @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
      }
    }

    // Places location in the top right
    .location {
      position: absolute;
      right: 0;
      // Center the location between the place text (i.e. align baseline)
      top: ($timeline-place-font-size - $timeline-location-font-size) / 2;

      font-size: $timeline-location-font-size;
      color: $slateGray;

      // FontAwesome Icons
      svg {
        margin-right: 5px;
      }

      // Do not show on xsmall devices because it overlaps the title
      @include media-breakpoint-down(smxx) {
        display: none;
      }
    }

    .location-stacked {
      font-size: $timeline-location-font-size;
      font-style: italic;
      color: $slateGray;

      margin: 3px 0 15px 0;

      // FontAwesome Icons
      svg {
        margin-right: 5px;
      }

      // This location is stacked below the title/date and is only shown on xsmall devices
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .job-meta {
      position: static;

      // Place this on the left hand side on larger devices
      @include media-breakpoint-up(lg) {
        position: absolute;
        left: -$job-meta-max-width - $timeline-line-padding-left -
          $timeline-line-padding-right;
        top: 0;
      }

      @include media-breakpoint-up(sm) {
        margin-bottom: 15px;
      }

      .title {
        font-size: $timeline-title-font-size;
        color: $primary;

        max-width: none;

        // Set maximum width when title is shown on left side
        @include media-breakpoint-up(lg) {
          max-width: $job-meta-max-width;
        }
      }

      .time {
        color: $slateGray;
      }
    }

    .job-desc {
      color: $gray-600;
    }
  }
}
