@import "functions";
@import "variables";
@import "~bootstrap/scss/bootstrap";

@import "header";
@import "home";
@import "about";
@import "skills";
@import "projects";
@import "experience";
@import "education";
@import "footer";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $bonJour;
}

.content {
  > .row {
    margin-bottom: 100px;
  }
}

.minimalist.card {
  box-shadow: $card-box-shadow;

  padding: 45px;

  @include media-breakpoint-down(md) {
    padding: 25px;
  }

  > .card-header {
    font-size: 1.75rem;
    font-weight: bolder;
    color: $gray-800;

    text-align: center;

    background-color: transparent !important;
    border: none;

    padding: 0;
    margin-bottom: 45px;
  }

  > .card-body {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-800;
}

@include media-breakpoint-only(xs) {
  .px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include media-breakpoint-down(smx) {
  .px-xs-d-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@include media-breakpoint-down(smx) {
  .px-smx-d-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// Fixed in Bootstrap 4.2 but had to downgrade
.col-auto {
  width: 100% !important;
}
