#education {
  // Add bottom margin to columns when they are going to be wrapped to next row
  // Do not add the bottom margin to the last child because it just adds more space
  @include media-breakpoint-down(sm) {
    .row > div:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-only(md) {
    div[class*="col"] {
      padding: 0;
    }
  }

  .title {
    font-size: 1.25rem;
    font-weight: bolder;
    color: $primary;

    @include media-breakpoint-only(md) {
      font-size: 1rem;
    }
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 600;
    color: $daintree;

    margin: 0;
    padding: 0;
  }

  p {
    font-size: 1rem;
    font-weight: 300;

    margin: 0;
    padding: 0;

    b {
      font-weight: bold;
    }

    &.location {
      font-size: 0.85rem;
      font-style: italic;
      color: $slateGray;

      svg {
        margin-right: 5px;
      }
    }

    &.date {
      color: $secondary;
    }

    &.gpa {
      color: $gray-600;
      margin: 8px 0 0 0;
    }
  }
}
