#skills {
  h4 {
    color: $primary;
  }

  // Selects any columns by searching for any div that has a class that strarts with col
  div[class*="col"] {
    // Add bottom margin to columns when they are going to be wrapped to next row
    // Do not add the bottom margin to the last child because it just adds more space
    &:not(:last-child) {
      @include media-breakpoint-down(md) {
        margin-bottom: 50px;
      }
    }

    &.show-sm-md {
      display: none;

      @include media-breakpoint-between(sm, md) {
        display: block;
      }
    }

    &.hide-sm-md {
      display: block;

      @include media-breakpoint-between(sm, md) {
        display: none;
      }
    }

    @include media-breakpoint-between(xs, smxx) {
      &.mb-xsm-0 {
        margin-bottom: 0;
      }
    }
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  abbr.address {
    // Hide at every other breakpoint
    display: none;

    // Show this span on large screen
    @include media-breakpoint-only(lg) {
      display: inline;
    }
  }

  .list-checkmark {
    padding-left: 10px;
    margin-bottom: 0;
    list-style: none;

    // FontAwesome icons
    svg {
      color: $success;
      margin-right: 5px;
    }
  }
}
