#projects {
  .language-row {
    // margin-bottom: 60px;
    margin-bottom: 10px;

    // Center nav
    div[class*="col"] {
      margin: 0 auto;
    }

    &::after {
      content: "";
      width: 100%;

      border-bottom: 1px solid $gray-300;
    }
  }

  .language-nav {
    font-size: 0.875rem;

    // Center items
    // This is most relevant on xs screens when there is 2 lines for languages, causes it to be nicely centered
    justify-content: center;

    .nav-link {
      color: $gray-600;
      cursor: pointer;

      // Set padding/margin between each button on xs & sm devices
      padding: 0.4em;
      margin: 0 0.1em 0.4em 0.1em;

      // Increase left/right padding and margin on large devices
      @include media-breakpoint-up(md) {
        padding: 0.4em 0.75em;
        margin: 0 0.4em 0.4em 0.4em;
      }

      // This styling is also applied for focus with keyboard (uses focus-visible to disregard mouse focus)
      html:not(.is-touching) &:hover,
      &.focus-visible:focus {
        color: $alabaster;
        background-color: lighten($havelockBlue, 10%);
        border-radius: 4px;

        // Needed for focus to remove blue border around item
        outline: none;
      }

      // Gray out text when disabled
      &.disabled {
        color: $gray-500;
        cursor: default;
      }
    }

    // Set active styling
    // For the active class, this is a custom attribute developers can assign to nav-links to show they're selected, so we show the styling
    // But, for the active pseudo-state, we only want to show the styling on touch screen devices so there is some feedback when you click on an item
    // because the hover state is removed for mobile devices due to it being sticky
    // Long story short, :active used for touchscreen devices, :hover used for cursor devices. Mutually exclusive
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active,
    html.is-touching & .nav-link:active {
      color: $alabaster !important;
      background-color: $havelockBlue !important;
      border-radius: 4px !important;
    }
  }

  .disclaimer {
    font-size: 0.85rem;
    font-style: italic;
    color: $text-muted;

    margin-bottom: 35px;
  }

  // Add margin to top/bottom of card
  .projects-row {
    .card {
      margin: 0;
      padding: 0;

      height: 100%;
    }

    > div[class*="col"] {
      margin: 15px 0;

      overflow: hidden;

      // TODO Eventually switch to better transition mechanism, looks better than nothing but could be improved
      // opacity: 1;
      // transition: flex-basis 0.15s ease-in-out, opacity 0.15s ease-out;

      // Hides project
      &.project-hide {
        // flex-basis: 0;
        // opacity: 0;
        // padding: 0;
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      // Remove bottom margin for last element (only one element per row for medium & below devices)
      div[class*="col"]:last-child {
        margin: 15px 0 0 0;
      }
    }

    @include media-breakpoint-up(lg) {
      // Remove bottom margin for last two elements (two elements per row for large & up devices)
      div[class*="col"]:nth-last-child(-n + 2) {
        margin: 15px 0 0 0;
      }
    }

    .card-title {
      font-size: 1.25rem;
      font-weight: bolder;
      color: $daintree;

      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }

      a {
        color: $daintree;
      }
    }

    .card-desc {
      color: $gray-600;
    }

    .technology-list {
      // Inline list
      padding-left: 0;
      list-style: none;

      @include media-breakpoint-up(smxx) {
        margin-top: -8px;
      }

      li {
        display: inline-block;
      }

      // Reduce spacing between elements
      li:not(:last-child) {
        margin-right: 0.25em;
      }
    }

    .badge-custom {
      background-color: $havelockBlue;
      color: $white;
    }

    .card-image-container {
      position: relative;
      margin: 0 auto;

      // Fix for IE10 & IE11 center images
      align-self: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;

      // Fix for Edge to show mouse pointer on image
      cursor: pointer;

      &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: none;
        transition: all 0.35s ease-in-out;

        cursor: pointer;
        border-radius: 5px;
      }

      // Show a light tint on the image for hover/click effect
      // Use :hover pseudo-state for non-touch devices (i.e. laptops, etc) (when mouse is over the component, it will be on)
      // Use :active pseudo-state for touch devices (will be valid when the img is being clicked)
      html:not(.is-touching) &:hover:before,
      html.is-touching &:active:before {
        background: rgba($primary, 0.25);
      }
    }

    .card-img-top,
    .card-img-bottom {
      cursor: pointer;

      // Set image to have a maximum height and to keep aspect ratio if it reaches that
      width: initial;
      max-width: 100%;
      max-height: 250px;
      margin: 0 auto;
    }
  }
}

#lightboxBackdrop {
  button,
  svg {
    outline: none;
  }
}
